import GitHubCalendar from "react-github-calendar";

import BaseCard from "./BaseCard";

import SectionHeader from "../common/SectionHeader";
import SectionBody from "../common/SectionBody";
import SocialsLink from "../common/SocialsLink";
import { AtSymbolIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";

type ContactCardProps = {
  dark: boolean;
}

function ContactCard({ dark }: ContactCardProps) {
  const links = [
    { type: "gitHub", link: "https://github.com/aaronmdomingo" },
    { type: "linkedIn", link: "https://www.linkedin.com/in/aaronjmdomingo" },
    { type: "resume", link: "https://drive.google.com/file/d/1_lQwtHNZbuBtH-rU9bZ35dnGf0bcXXoB/view" },
    { type: "email", link: "mailto:aaronmdomingo@gmail.com" }
  ]
  const baseColor = dark ? "#eab308" : "#3b82f6"

  return (
    <BaseCard>
      <div className="bg-white/85 dark:bg-slate-800/85 duration-150 rounded-xl p-4">
        <SectionHeader title="CONNECT WITH ME!" />
        <SectionBody text="I’m always on the lookout for new things to learn and explore. Right now, I’m playing around with Go, NextJS, and Vite. If you’re curious about the side projects I’m working on, check out my GitHub profile. And if you ever want to chat about programming or anything tech-related, just drop me a message!" />
        <SectionBody text="Have a great day!" />
        <div className="text-black dark:text-white my-8 w-full">
          <GitHubCalendar username="aaronmdomingo" color={baseColor} style={{ margin: "auto" }} />
        </div>
        <ul className="w-full flex flex-col justify-around item-center mb-8 text-md text-black dark:text-white">
          <div className="w-full flex justify-center items-center h-12">
            <MapPinIcon className="size-6 mr-2" /> Orange County, CA
          </div>
          <div className="w-full flex justify-center items-center h-12">
            <PhoneIcon className="size-6 mr-2" /> (714)-588-9237
          </div>
          <div className="w-full flex justify-center items-center h-12">
            <AtSymbolIcon className="size-6 mr-2" /> aaronmdomingo@gmail.com
          </div>
        </ul>
        <div className="mb-8">
          <ul className="flex justify-around items-center mx-auto my-auto max-w-[1000px]">
            {links.map(link => <SocialsLink key={link.link} link={link.link} type={link.type} dark={dark} />)}
          </ul>
        </div>
      </div>
    </BaseCard>
  )
}

export default ContactCard
