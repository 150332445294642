import { useState, useEffect } from "react";

type DividerProps = {
  size?: string;
  inverseColors?: boolean;
}

function Divider({ size = "lg", inverseColors = false }: DividerProps) {
  const [loaded, setLoaded] = useState(false);

  const maxWidthClass = size === "lg" ? "max-w-[500px]" : "max-w-[300px]"

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 200)
  }, [])

  return (
    <hr className={`h-1 rounded-md border-t-0 ${inverseColors ? "bg-slate-600 dark:bg-slate-200" : "bg-slate-200 dark:bg-slate-600"} w-full mx-auto mt-4 ${loaded ? `${maxWidthClass}` : "max-w-[150px]"} transition-all duration-150`} />
  )
}

export default Divider
