
type SectionBodyProps = {
  text: string;
}

function SectionBody({ text }: SectionBodyProps) {
  return (
    <div className="max-w-[1000px] mx-auto mb-4 text-black dark:text-white text-md lg:text-lg tracking-tight leading-relaxed">
      {text}
    </div>
  )
}

export default SectionBody;
