import { useState, useEffect } from "react";

type BaseCardProps = {
  children: React.ReactNode
}

function BaseCard({ children }: BaseCardProps) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 150)
  }, [])

  return (
    <section className={`sticky z-10 block w-full my-4 mx-auto bg-transparent opacity-0 ${loaded && "opacity-100"} rounded-xl shadow-md duration-150`}>
      {children}
    </section>
  )
}

export default BaseCard
