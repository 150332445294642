import { useState, useEffect } from "react";
import Navbar from './nav-bar/Navbar';
import MemoizedParticlesCover from "./particles/ParticlesCover";
import AboutCard from "./cards/AboutCard";
import ExperienceCard from "./cards/ExperienceCard";
import ContactCard from "./cards/ContactCard";
import FooterCard from "./cards/FooterCard";

import { ABOUT, EXPERIENCE, CONTACT } from "./constants";

function App() {
  const localStorageValue = localStorage.getItem("darkMode");
  const [dark, setDark] = useState(false);
  const [selectedLink, setSelectedLink] = useState(ABOUT)
  const [typeWriterCompleted, setTypeWriterCompleted] = useState(false);


  const darkModeHandler = () => {
    localStorage.setItem("darkMode", `${!dark}`)
    setDark(!dark);
    document.body.classList.toggle("dark");
  }

  useEffect(() => {
    const setMode = () => {
      if (!!localStorageValue && localStorageValue === "true") {
        setDark(true);
        document.body.classList.add("dark");
      }
    }

    setMode();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderCard = () => {
    switch(selectedLink) {
      case ABOUT:
        return <AboutCard dark={dark} typeWriterCompleted={typeWriterCompleted} setTypeWriterToCompleted={() => setTypeWriterCompleted(true)} />
      case EXPERIENCE:
        return <ExperienceCard dark={dark} />
      case CONTACT:
        return <ContactCard dark={dark} />
    }
  }

  return (
    <div className="App bg-slate-100 dark:bg-slate-900 transition-colors duration-150">
      <MemoizedParticlesCover dark={dark} />
      <div className="grid min-h-[100vh] w-full px-4 dark:bg-slate-900 transition-colors duration-150">
        <div className="max-w-[1440px] min-h-[100vh] m-auto w-full">
          <Navbar
            dark={dark}
            selectedLink={selectedLink}
            darkModeHandler={darkModeHandler}
            setSelectedLink={setSelectedLink}  />
          {
            renderCard()
          }
          <FooterCard />
        </div>
      </div>
    </div>
  );
}

export default App;
