import { ABOUT, EXPERIENCE, CONTACT, DARK_GRADIENT_ACCENT_CLASS, DARK_HOVER_ACCENT_CLASS, LIGHT_GRADIENT_ACCENT_CLASS, LIGHT_HOVER_ACCENT_CLASS } from "../constants";
import { HomeIcon, BriefcaseIcon, UserIcon } from "@heroicons/react/24/solid";

type NavbarButtonProps = {
  linkName: string;
  selectedLink: string;
  setSelectedLink: (linkName: string) => void;
  openDrawer?: boolean;
  setOpenDrawer?: (openDrawer: boolean) => void;
  withIcon?: boolean;
  dark: boolean;
}

function NavbarButton({ linkName, selectedLink, setSelectedLink, withIcon, openDrawer, setOpenDrawer, dark }: NavbarButtonProps) {
  const linkSelected = linkName === selectedLink;
  const linkSelectedClass = dark ? DARK_GRADIENT_ACCENT_CLASS : LIGHT_GRADIENT_ACCENT_CLASS
  const linkHoverClass = dark ? DARK_HOVER_ACCENT_CLASS : LIGHT_HOVER_ACCENT_CLASS
  const textSelectedClass = dark ? "!text-black" : "!text-white"

  const renderIcon = () => {
    switch(linkName) {
      case ABOUT:
        return <HomeIcon />
      case EXPERIENCE:
        return <BriefcaseIcon />
      case CONTACT:
        return <UserIcon />
    }
  }

  const handleOnClick = () => {
    setSelectedLink(linkName)
    if (!!setOpenDrawer) {
      setOpenDrawer(!openDrawer)
    }
  }

  return (
    <li className={`flex items-center justify-center w-auto p-1 font-sans text-lg antialiased font-medium leading-relaxed text-inherit rounded-3xl ${linkSelected ? `${linkSelectedClass} ${textSelectedClass}` : `${linkHoverClass} bg-transparent`} transition-all`}>
      <button className={`flex items-center px-3`} onClick={handleOnClick}>
        {
          withIcon && (
            <span className="mr-2 text-inherit size-4">
              {renderIcon() }
            </span>
          )
        }
        { linkName }
      </button>
    </li>
  )
}

export default NavbarButton;
