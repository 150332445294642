import { useState, useEffect } from "react";

function FooterCard() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 150)
  }, [])

  return (
    <footer className={`sticky text-sm lg:text-base z-10 block w-full my-4 mx-auto flex justify-center bg-white/85 dark:bg-slate-800/85 shadow-md duration-150 rounded-xl p-4 opacity-0 ${loaded && "opacity-100"} text-center text-gray-900 dark:text-slate-200`}>
      &copy; 2024 Aaron Domingo. All logos appearing on site are the property of their respective owners.
    </footer>
  )
}


export default FooterCard;
