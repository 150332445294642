import Divider from "./Divider";
import { DARK_TEXT_ACCENT_CLASS, LIGHT_TEXT_ACCENT_CLASS } from "../constants"

type SectionHeaderProps = {
  title: string;
}

function SectionHeader({ title }: SectionHeaderProps) {
  return (
    <div className='py-4'>
      <h2 className={`font-bold text-3xl text-center leading-tight ${DARK_TEXT_ACCENT_CLASS} ${LIGHT_TEXT_ACCENT_CLASS} duration-150`}>{title}</h2>
      <Divider />
    </div>
  )
}

export default SectionHeader
